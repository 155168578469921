/**
 * 商行管理- -合同管理
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Card,
  Table,
  Select,
  Row,
  Col,
  Checkbox,
  Icon,
  DatePicker,
  Tooltip,
  TreeSelect,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { strSplit } from '../../utils/utils';
import { STALL_TYPE, CONTRACT_TYPES } from '../../utils/deviceType';

import './index.less';

import { fetchAllStallsConfig, marketList } from '../../state/action/stall';
import { getStallList } from '../../state/action/basic';
import { getListPacts } from '../../state/action/contract';
import { exportPacts } from '../../state/request/contract';

import ContractDetail from './index/detail';
import { exportFile } from '../../utils/utils';
import { getAllDistrictInfo } from '../../state/action/mergeSet';

const { Option } = Select;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const format1 = 'YYYY-MM-DD 00:00:00';
const format2 = 'YYYY-MM-DD 23:59:59';

@connect(
  (state) => {
    return {
      stall: state.stall,
      basic: state.basic,
      contract: state.contract,
      mergeSet: state.mergeSet,
    };
  },
  {
    getListPacts,
    fetchAllStallsConfig,
    marketList,
    getStallList,
    getAllDistrictInfo,
  },
)
@Form.create()
class ContractManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expand: false, //筛选图标
      search: {},
      defaultCurrent: 1,
      record: {},
      visible: false,
      fileList: [],
    };
  }
  componentDidMount() {
    this.getAllDistrictInfo();
    this.getMarketList(); //获得市场列表
    this.getListPacts({ pageNo: 1, pageSize: 10 }); //获取合同列表
  }

  //获取合同列表
  async getListPacts(params) {
    await this.props.getListPacts(params);
  }
  // 获取档口列表
  async fetchAllStallsGroupInfos(params) {
    await this.props.fetchAllStallsConfig(params);
  }
  // 获取省市区
  getAllDistrictInfo() {
    this.props.getAllDistrictInfo();
  }
  // 获得市场列表
  async getMarketList() {
    await this.props.marketList({});
  }
  // 获取档口列表
  async getStallList(value) {
    await this.props.getStallList(value);
  }

  //筛选收起
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      [
        'cityCode',
        'marketId',
        'stallId',
        'pactNo',
        'pactStartTime',
        'payStartTime',
        'firstDepartment',
        'pioneerDivision',
        'signBd',
        'pactType',
        'systemType',
        'stallName',
        'manageType',
        'renewSource',
        'orderNo',
      ],
      (errors, values) => {
        if (!!errors) {
          return;
        }
        const {
          cityCode,
          marketId,
          stallId,
          pactNo,
          pactStartTime,
          payStartTime,
          firstDepartment,
          pioneerDivision,
          signBd,
          manageType,
          stallName,
          renewSource,
          orderNo,
        } = values;
        values = {
          pactStartTime:
            pactStartTime && pactStartTime.length === 2
              ? moment(pactStartTime[0].format(format1)).valueOf()
              : null,
          pactEndTime:
            pactStartTime && pactStartTime.length === 2
              ? moment(pactStartTime[1].format(format2)).valueOf()
              : null,
          payStartTime:
            payStartTime && payStartTime.length === 2
              ? moment(payStartTime[0].format(format1)).valueOf()
              : null,
          payEndTime:
            payStartTime && payStartTime.length === 2
              ? moment(payStartTime[1].format(format2)).valueOf()
              : null,
          cityCode,
          marketId,
          stallId,
          pactNo,
          firstDepartment,
          pioneerDivision,
          signBd,
          enable: manageType && manageType.key ? Number(manageType.key) : null,
          //合同类型 全选或者全不选 则不传值给后端
          pactType: values.pactType
            ? values.pactType.length === 0 || values.pactType.length === 3
              ? null
              : values.pactType
            : null,
          //系统类型 全选或者全不选 则不传值给后端
          systemType: values.systemType
            ? values.systemType.length === 0 || values.systemType.length === 4
              ? null
              : values.systemType
            : null,
          stallName,
          renewSource: renewSource && renewSource.key ? Number(renewSource.key) : null,
          orderNo,
        };

        this.setState({ search: { ...values }, defaultCurrent: 1 });
        this.getListPacts({ ...values, pageSize: 10, pageNo: 1 });
      },
    );
  };
  // 导出
  exportData = async () => {
    const { search } = this.state;

    const params = {
      ...search,
      pageSize: 500,
      pageNo: 1,
    };

    const response = await exportPacts(params);
    exportFile(response, '合同信息.xlsx');
  };

  //刷新主页面table
  refreshIndex = (params) => {
    this.getListPacts(params);
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.setState({ defaultCurrent: obj.current });
    const { search } = this.state;
    this.getListPacts({
      pageSize: obj.pageSize,
      pageNo: obj.current,
      ...search,
    });
  };

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.getListPacts({ pageNo: 1, pageSize: 10 }); //获取合同列表
  };

  onCityChange = () => {};
  // 市场选择框的onchange事件
  selectMarketEvent = (value) => {
    let keyCode = { marketId: value };
    this.getStallList(keyCode);
    //清空打商行原先选中的值
    this.props.form.setFieldsValue({ stall: '' });
  };
  showModal = (record, value) => {
    let fileArr = [];
    if (record.bdImageResultList && record.bdImageResultList.length > 0) {
      //回显合同附件
      for (let i = 0; i < record.bdImageResultList.length; i++) {
        const arr = record.bdImageResultList[i].fileName.split('.');
        if (arr[arr.length - 1] === 'pdf') {
          fileArr.push({
            uid: i,
            name: record.bdImageResultList[i].fileName,
            status: 'done',
            url1: record.bdImageResultList[i].fileUrl,
          });
        } else {
          fileArr.push({
            uid: i,
            name: record.bdImageResultList[i].fileName,
            status: 'done',
            url: record.bdImageResultList[i].fileUrl,
          });
        }
      }
    }
    this.setState({
      record: record,
      visible: value,
      fileList: fileArr,
    });
  };
  render() {
    const {
      mergeSet: {
        districtInfo: { mapping },
      },
      stall: { marketData = [] },
      contract: { listPactsData = {} },
    } = this.props;
    const { origins } = mapping || [];
    //去掉区
    if (origins instanceof Array) {
      for (const iterator of origins) {
        for (const iteratorChildren of iterator.children) {
          delete iteratorChildren.children;
        }
      }
    }
    const { getFieldDecorator } = this.props.form;
    const { pageList, pageNo = 1, pageSize, totalSize = 1 } = listPactsData;
    const { visible, record, fileList, defaultCurrent } = this.state;
    // 表格的分页的设计
    const pagination = {
      pageSize: pageSize,
      total: totalSize,
      current: pageNo,
    };

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        width: 80,
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '商行ID',
        dataIndex: 'stallId',
        width: 90,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '商行名称',
        dataIndex: 'stallName',
        width: 120,
        render: (text) => {
          const textTip = <span>{text}</span>;
          return (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{strSplit(text, 8)}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '市场名',
        dataIndex: 'stallMarketName',
        width: 120,
        render: (text) => {
          const textTip = <span>{text}</span>;
          return (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{strSplit(text, 8)}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '归属地',
        dataIndex: 'provinceName',
        width: 120,
        render: (text, r) => {
          const textTip = <span>{`${r.provinceName || ''} ${r.cityName || ''}`}</span>;
          return (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{`${r.provinceName || ''} ${r.cityName || ''}`}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '签约人',
        dataIndex: 'signUser',
        width: 100,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '签约人手机',
        dataIndex: 'signPhone',
        width: 120,
        render: (text) => {
          return text ? text.substring(0, 3) + '****' + text.substring(7, text.length) : '--';
        },
      },
      {
        title: '合同编号',
        dataIndex: 'pactNo',
        width: 120,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '支付订单号',
        dataIndex: 'orderNo',
        width: 120,
        render: (text) => {
          //后端的数据类型是long类型，js不能识别过长的数字，所以后端返回的是字符串，但是为0的字符串要显示--
          return text == null || text === '0' ? '--' : text;
        },
      },
      {
        title: '签约日期',
        dataIndex: 'pactStartTime',
        key: 'pactStartTime',
        width: 120,
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD') : '--';
        },
      },
      {
        title: '有效期',
        dataIndex: 'validity',
        width: 90,
        render: (text, record) => {
          const { pactType } = record;
          if (pactType === 3) {
            return text ? text + '天' : '- -';
          } else {
            return text ? text + '年' : '- -';
          }
        },
      },
      //合同类型：1.新签合同，2.续费合同
      {
        title: '合同类型',
        dataIndex: 'pactType',
        width: 90,
        render: (text) => {
          return CONTRACT_TYPES.get(text) || '--';
        },
      },
      //商行版本：1.专业版，2.基础版
      {
        title: '商行版本',
        dataIndex: 'systemType',
        width: 90,
        render: (text) => {
          return STALL_TYPE.get(text);
        },
      },
      {
        title: '合同金额',
        dataIndex: 'pactAmount',
        width: 90,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '回款金额',
        dataIndex: 'payAmount',
        width: 90,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '回款日期',
        dataIndex: 'payTime',
        width: 120,
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD') : '--';
        },
      },
      {
        title: '业务线',
        dataIndex: 'firstDepartment',
        width: 90,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '所属部门',
        dataIndex: 'pioneerDivision',
        width: 90,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '签约BD',
        dataIndex: 'signBd',
        width: 90,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '是否生效',
        dataIndex: 'enable',
        width: 90,
        render: (_, record) => {
          const { enable } = record;
          if (enable === 1) {
            return '生效中';
          }
          if (enable === 0) {
            return <span style={{ color: '#EC634A' }}>已失效</span>;
          }
          return '--';
        },
      },
      {
        title: '最后一次操作人',
        dataIndex: 'updateName',
        width: 140,
        render: (t) => t || '--',
      },
      {
        title: '操作',
        dataIndex: 'option',
        width: 90,
        render: (_, record) => {
          return (
            <a
              href='/'
              onClick={(e) => {
                e.preventDefault();
                this.showModal(record, true);
              }}
            >
              编辑
            </a>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const layout = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 };

    return (
      <div className='contractManagement'>
        <PageHeaderLayout>
          <div>
            <Card className='listCard' bordered={false}>
              <div className='searchModule'>
                <Form
                  {...formItemLayout}
                  layout='inline'
                  onSubmit={this.handleSearch}
                  className='login-form'
                >
                  <Row gutter={12}>
                    <Col {...layout}>
                      <FormItem label='城市'>
                        {getFieldDecorator('cityCode', {
                          rules: [{ required: false, message: '' }],
                        })(
                          <TreeSelect
                            allowClear
                            showSearch
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={origins}
                            placeholder='请选择城市'
                            treeDefaultExpandAll={false}
                            onChange={this.onCityChange}
                            filterTreeNode={(input, treeNode) => {
                              return treeNode.props.title.indexOf(input) >= 0;
                            }}
                          />,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='市场名称'>
                        {getFieldDecorator('marketId', {
                          rules: [{ required: false, message: '请选择市场!' }],
                        })(
                          <Select
                            placeholder='请选择市场'
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {marketData.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='商行名称'>
                        {getFieldDecorator('stallName', {
                          rules: [{ required: false, message: '请输入商行名称!' }],
                        })(<Input placeholder='请输入商行名称' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='商行ID'>
                        {getFieldDecorator('stallId', {
                          rules: [
                            {
                              required: false,
                              message: '请输入商行ID',
                            },
                            {
                              pattern: /^\d+$/,
                              message: '只能输入数字!',
                            },
                          ],
                        })(<Input placeholder='请输入商行ID' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={12} style={{ display: this.state.expand ? 'none' : 'block' }}>
                    <Col {...layout}>
                      <FormItem label='合同类型'>
                        {getFieldDecorator('pactType', {
                          rules: [{ required: false, message: '' }],
                        })(
                          //合同类型 1.新签合同，2.续费合同
                          <Checkbox.Group
                            style={{ width: '100%', paddingBottom: '15px' }}
                            onChange={this.onStateChange}
                          >
                            {[...CONTRACT_TYPES].map(([k, v]) => (
                              <Checkbox value={k} key={k}>
                                {v}
                              </Checkbox>
                            ))}
                          </Checkbox.Group>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='业务线'>
                        {getFieldDecorator('firstDepartment', {
                          rules: [{ required: false, message: '请输入业务线' }],
                        })(<Input placeholder='请输入业务线' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='所属部门'>
                        {getFieldDecorator('pioneerDivision', {
                          rules: [{ required: false, message: '请输入所属部门!' }],
                        })(<Input placeholder='请输入所属部门' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='签约BD'>
                        {getFieldDecorator('signBd', {
                          rules: [{ required: false, message: '请输入签约BD!' }],
                        })(<Input placeholder='请输入签约BD' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={12} style={{ display: this.state.expand ? 'none' : 'block' }}>
                    <Col {...layout}>
                      <FormItem label='商行版本'>
                        {getFieldDecorator('systemType', {
                          rules: [{ required: false, message: '' }],
                        })(
                          <Checkbox.Group
                            style={{ width: '100%', paddingBottom: '15px' }}
                            onChange={this.onStateChange}
                          >
                            {[...STALL_TYPE].map(([k, v]) => (
                              <Checkbox value={k} key={k}>
                                {v}
                              </Checkbox>
                            ))}
                          </Checkbox.Group>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='签约日期'>
                        {getFieldDecorator('pactStartTime', {
                          rules: [{ required: false, message: '请选择时间' }],
                        })(
                          <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始时间', '结束时间']}
                          ></RangePicker>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='回款日期'>
                        {getFieldDecorator('payStartTime', {
                          rules: [{ required: false, message: '请选择时间' }],
                        })(
                          <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始时间', '结束时间']}
                          ></RangePicker>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='合同编号'>
                        {getFieldDecorator('pactNo', {
                          rules: [{ required: false, message: '请输入合同编号!' }],
                        })(<Input placeholder='请输入合同编号' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={12} style={{ display: this.state.expand ? 'none' : 'block' }}>
                    <Col {...layout}>
                      <FormItem label='是否生效'>
                        {getFieldDecorator('manageType', {
                          rules: [{ required: false, message: '请选择是否生效!' }],
                        })(
                          //是否生效 0-已失效 1-生效中
                          <Select
                            labelInValue
                            placeholder='请选择是否生效'
                            style={{ width: 150 }}
                            allowClear
                          >
                            <Option value='0' key='0'>
                              已失效
                            </Option>
                            <Option value='1' key='1'>
                              生效中
                            </Option>
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='续费来源'>
                        {getFieldDecorator('renewSource', {
                          rules: [{ required: false, message: '请选择续费来源!' }],
                        })(
                          //是否生效 0-已失效 1-生效中
                          <Select
                            labelInValue
                            placeholder='请选择续费来源'
                            style={{ width: 150 }}
                            allowClear
                          >
                            <Option value='1' key='1'>
                              线上续费
                            </Option>
                            <Option value='2' key='2'>
                              人工续费
                            </Option>
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='支付订单号：'>
                        {getFieldDecorator('orderNo', {
                          rules: [
                            {
                              required: false,
                              message: '请输入支付订单号',
                            },
                            {
                              pattern: /^\d+$/,
                              message: '只能输入数字!',
                            },
                          ],
                        })(<Input placeholder='请输入支付订单号' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row style={{ padding: '5px 10px' }}>
                    <Button onClick={this.handleReset}>重置</Button>
                    <Button type='primary' htmlType='submit' style={{ marginLeft: '8px' }}>
                      查询
                    </Button>
                    <Button style={{ marginLeft: '20px' }} onClick={this.exportData}>
                      导出
                    </Button>
                    <span style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={this.toggle}>
                      收起筛选 <Icon type={this.state.expand ? 'down' : 'up'} />
                    </span>
                  </Row>
                </Form>
              </div>
              <div>
                <Table
                  rowKey={(_, index) => index}
                  dataSource={pageList}
                  columns={columns}
                  pagination={pagination}
                  onChange={this.pageSwitch.bind(this)}
                  loading={this.state.loading}
                  scroll={{ x: 1300 }}
                />
              </div>
            </Card>
          </div>
        </PageHeaderLayout>
        {visible && (
          <ContractDetail
            visible={visible}
            record={record}
            fileList={fileList}
            showModal={this.showModal}
            refreshIndex={this.refreshIndex}
            defaultCurrent={defaultCurrent}
          ></ContractDetail>
        )}
      </div>
    );
  }
}

export default ContractManagement;
