export const typeEnum = [
  { label: '线上付费', value: 1, key: 'CREDIT_SIGN' },
  { label: '咨询类', value: 2, key: 'CONSULT' },
];

export const categoryEnum = [
  { label: '产品功能', key: 1},
  { label: '线下服务', key: 2 },
];

export const status = [
  { label: '启用', value: 1 },
  { label: '禁用', value: 0 },
];


export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

// 广告图-展示位置
export const positionEnum = [
  { label: '首页', value: 1, key: 'APP_INDEX' },
  { label: '买家详情页', value: 2, key: 'APP_BUYER_DETAIL' },
  { label: '货主通转介绍广告', value: 101, key: 'SUPPLIER_REFERRAL_ADVERTISEMENT' }
]
