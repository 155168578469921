import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { Form, Button, Row, Col, TreeSelect, Table, Select, Input, DatePicker, Tag } from 'antd';
import { getAllDistrictInfo } from '../../state/action/mergeSet';
import { marketList, stallInfos } from '../../state/action/stall';
import { getAllSale } from '../../state/action/salesman';
import moment from 'moment';
import './stallActiveInfo.less';
import { isEmptyObject } from '../../utils/reg';

const FormItem = Form.Item;
const { Column } = Table;
const { RangePicker } = DatePicker;
const pageSize = 10;
const format = 'YYYY-MM-DD 00:00:00';

@connect(
  (state) => {
    return { mergeSet: state.mergeSet, stall: state.stall, salesman: state.salesman };
  },
  { getAllDistrictInfo, marketList, stallInfos, getAllSale },
)
@Form.create()
class StallActiveInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      marketStallData: {}, // 页面数据
      pageNo: 1, // 请求页码
      loading: true, // 加载中
      searchMarketId: !isEmptyObject(this.props.location.state)
        ? this.props.location.state.marketId
        : undefined,
    };
  }

  componentDidMount() {
    this.getData({
      pageNo: this.state.pageNo,
      pageSize: pageSize,
      marketId: this.state.searchMarketId,
    });
    this.getAllDistrictInfo();
    this.getMarketList();
    this.props.getAllSale(); //获取所有的销售人员
  }

  /**
   * 获取数据源
   */
  getData = (params) => {
    this.props.stallInfos(params);
    this.setState({ loading: false, pageNo: params.pageNo });
  };

  // 获取省市区
  getAllDistrictInfo(params) {
    this.props.getAllDistrictInfo(params);
  }
  // 获得市场列表
  getMarketList() {
    this.props.marketList({});
  }

  //执行搜索
  search = () => {
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      const createTimeStart =
        fieldsValue['createTime'] && fieldsValue['createTime'].length === 2
          ? moment(fieldsValue['createTime'][0].format(format)).valueOf()
          : null;
      const createTimeEnd =
        fieldsValue['createTime'] && fieldsValue['createTime'].length === 2
          ? moment(fieldsValue['createTime'][1].clone().add(1, 'day').format(format)).valueOf()
          : null;
      const pactStartTimeStart =
        fieldsValue['pactStartTime'] && fieldsValue['pactStartTime'].length === 2
          ? moment(fieldsValue['pactStartTime'][0].format(format)).valueOf()
          : null;
      const pactStartTimeEnd =
        fieldsValue['pactStartTime'] && fieldsValue['pactStartTime'].length === 2
          ? moment(fieldsValue['pactStartTime'][1].clone().add(1, 'day').format(format)).valueOf()
          : null;

      // 查询
      this.getData({
        ...fieldsValue,
        pageNo: 1,
        pageSize: pageSize,
        createTimeStart: createTimeStart,
        createTimeEnd: createTimeEnd,
        pactStartTimeStart: pactStartTimeStart,
        pactStartTimeEnd: pactStartTimeEnd,
      });
    });
  };

  // 重置按钮
  handleReset = () => {
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      marketId: undefined,
    });

    //重置后重新查询
    this.search();
  };

  // 查询
  handleSearch = (e) => {
    e.preventDefault();
    this.search();
  };

  /**
   * 切换页面
   * @param {*} obj
   */
  pageSwitch = (obj) => {
    this.setState({ pageNo: obj.current }, () => {
      this.props.form.validateFields((errors, fieldsValue) => {
        if (!!errors) {
          return;
        }
        const createTimeStart =
          fieldsValue['createTime'] && fieldsValue['createTime'].length === 2
            ? moment(fieldsValue['createTime'][0].format(format)).valueOf()
            : null;
        const createTimeEnd =
          fieldsValue['createTime'] && fieldsValue['createTime'].length === 2
            ? moment(fieldsValue['createTime'][1].clone().add(1, 'day').format(format)).valueOf()
            : null;
        const pactStartTimeStart =
          fieldsValue['pactStartTime'] && fieldsValue['pactStartTime'].length === 2
            ? moment(fieldsValue['pactStartTime'][0].format(format)).valueOf()
            : null;
        const pactStartTimeEnd =
          fieldsValue['pactStartTime'] && fieldsValue['pactStartTime'].length === 2
            ? moment(fieldsValue['pactStartTime'][1].clone().add(1, 'day').format(format)).valueOf()
            : null;

        // 查询
        this.getData({
          ...fieldsValue,
          pageNo: obj.current,
          pageSize: pageSize,
          createTimeStart: createTimeStart,
          createTimeEnd: createTimeEnd,
          pactStartTimeStart: pactStartTimeStart,
          pactStartTimeEnd: pactStartTimeEnd,
        });
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, pageNo } = this.state;
    const {
      mergeSet: {
        districtInfo: { mapping },
      },
      stall: { marketData = [], stallInfoData = {} },
      salesman: { getAllSale = [] },
    } = this.props;
    const { origins } = mapping || [];
    const layout = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
    const { pageList = [], totalSize = 1 } = stallInfoData;
    const pagination = {
      current: pageNo,
      pageSize,
      total: totalSize,
    };
    if (origins instanceof Array) {
      for (const item of origins) {
        for (const itemChildren of item.children) {
          delete itemChildren.children;
        }
      }
    }

    return (
      <div>
        <PageHeaderLayout>
          <div className='stallActiveInfo'>
            <div className='ant-advanced-search-form'>
              <div>
                <Form layout='inline' onSubmit={this.handleSearch}>
                  <Row gutter={24}>
                    <Col {...layout}>
                      <FormItem label='省市'>
                        {getFieldDecorator('district', {
                          rules: [{ required: false, message: '请选择城市' }],
                        })(
                          <TreeSelect
                            allowClear
                            showSearch
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={origins}
                            placeholder='请选择城市'
                            treeDefaultExpandAll={false}
                            filterTreeNode={(input, treeNode) => {
                              return treeNode.props.title.indexOf(input) >= 0;
                            }}
                          />,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='市场名称'>
                        {getFieldDecorator('marketId', {
                          initialValue: this.state.searchMarketId,
                          rules: [{ required: false, message: '请选择市场' }],
                        })(
                          <Select
                            placeholder='请选择市场'
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {marketData.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='商行名称'>
                        {getFieldDecorator('name', {
                          rules: [{ required: false, message: '请输入商行名称!' }],
                        })(<Input placeholder='请输入商行名称' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='签约BD'>
                        {getFieldDecorator('saleId', {
                          rules: [{ required: false, message: '请输入签约BD名称!' }],
                        })(
                          <Select
                            placeholder='请选择签约BD'
                            allowClear
                            onChange={this.handleChangeBySignBD}
                            showSearch
                            filterOption={(input, option) => {
                              return (
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              );
                            }}
                          >
                            {getAllSale.map((v) => (
                              <Select.Option value={v.id} key={v.id}>
                                {v.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout} style={{ minWidth: 300 }}>
                      <FormItem label='签约日期'>
                        {getFieldDecorator('pactStartTime', {
                          rules: [{ required: false, message: '请选择时间' }],
                        })(
                          <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始时间', '结束时间']}
                          ></RangePicker>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout} style={{ minWidth: 300 }}>
                      <FormItem label='开通日期'>
                        {getFieldDecorator('createTime', {
                          rules: [{ required: false, message: '请选择时间' }],
                        })(
                          <RangePicker
                            disabledDate={this.disabledDate}
                            onChange={this.DateChange}
                            format='YYYY-MM-DD'
                            placeholder={['开始时间', '结束时间']}
                          ></RangePicker>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormItem>
                        <Button type='primary' htmlType='submit' style={{ marginTop: '20px' }}>
                          查询
                        </Button>
                        <Button
                          style={{ marginLeft: '20px', marginTop: '20px' }}
                          onClick={this.handleReset}
                        >
                          重置
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </div>

              <Table
                rowKey={(_, index) => index}
                dataSource={pageList}
                pagination={pagination}
                scroll={{ x: 800 }}
                align='center'
                bordered
                onChange={this.pageSwitch}
                loading={loading}
              >
                <Column
                  title='序号'
                  width={40}
                  dataIndex='index'
                  key='index'
                  render={(_, __, index) => {
                    return (pageNo - 1) * pageSize + index + 1;
                  }}
                />
                <Column title='所属城市' width={80} dataIndex='cityName' key='cityName' />
                <Column title='市场名称' width={200} dataIndex='marketName' key='marketName' />
                <Column title='商行ID' width={50} dataIndex='id' key='id' />
                <Column title='商行名称' width={100} dataIndex='name' key='name' />
                <Column
                  title='开通时间'
                  width={80}
                  dataIndex='createTime'
                  key='createTime'
                  render={(_, record) => {
                    const { createTime } = record;
                    if (createTime) {
                      return createTime !== 0 ? moment(createTime).format('YYYY-MM-DD') : '-';
                    } else {
                      return '-';
                    }
                  }}
                />
                <Column
                  title='签约时间'
                  width={80}
                  dataIndex='pactStartTime'
                  key='pactStartTime'
                  render={(_, record) => {
                    const { pactStartTime } = record;
                    if (pactStartTime) {
                      return pactStartTime !== 0 ? moment(pactStartTime).format('YYYY-MM-DD') : '-';
                    } else {
                      return '-';
                    }
                  }}
                />
                <Column title='签约BD' width={100} dataIndex='saleName' key='saleName' />
                <Column
                  title='商行状态'
                  width={100}
                  dataIndex='stallStatus'
                  key='stallStatus'
                  render={(_, record) => {
                    //0-沉睡 1-活跃 2-健康
                    const { stallStatus } = record;
                    if (stallStatus === 0) {
                      return (
                        <Tag color={'red'} key={'沉睡'}>
                          沉睡
                        </Tag>
                      );
                    } else if (stallStatus === 1) {
                      return (
                        <Tag color={'green'} key={'活跃'}>
                          活跃
                        </Tag>
                      );
                    } else if (stallStatus === 2) {
                      return (
                        <Tag color={'green'} key={'健康'}>
                          健康
                        </Tag>
                      );
                    }
                    return '';
                  }}
                />
              </Table>
            </div>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default StallActiveInfo;
