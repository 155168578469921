// 商行管理 -- 商行管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Button,
  Input,
  message,
  Table,
  Modal,
  Row,
  Col,
  DatePicker,
  TreeSelect,
  Checkbox,
  Select,
  Divider,
  Upload,
  Tag,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  STALL_SYSTEM_TYPE,
  STALL_TYPE,
  STALL_TYPES,
  STALL_SALE_TYPE,
  BUSINESS_TYPE,
} from '../../utils/deviceType';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';
import { getAuthorityUserInfo } from '../../utils/authority';
import './style.less';

import {
  listAll,
  updateStallManger,
  marketList,
  getStallManger,
  restPassword,
} from '../../state/action/stall';
import { getAllDistrictInfo } from '../../state/action/mergeSet';
import { exportStalls } from '../../state/request/stall';
import { exportFile } from '../../utils/utils';
import { hasPermissionCode } from '../../utils/authority';

const { Column } = Table;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const FormItem = Form.Item;
const format1 = 'YYYY-MM-DD 00:00:01';
const format2 = 'YYYY-MM-DD 23:59:59';
const userInfo = getAuthorityUserInfo();
const userId = (userInfo && userInfo.user_id) || -1;

@connect(
  (state) => {
    return { stall: state.stall, mergeSet: state.mergeSet };
  },
  { listAll, updateStallManger, marketList, getAllDistrictInfo, getStallManger, restPassword },
)
@Form.create()
class StallManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 10,
      district: '',
      marketId: '',
      pactStartTimeStart: null,
      pactStartTimeEnd: null,
      pactStatus: '',
      name: '',
      personName: '',
      boothPhone: '',
      createTimeStart: null,
      createTimeEnd: null,
      visible: false, //修改挡主账号
    };
  }

  componentDidMount() {
    this.getAllDistrictInfo();
    const {
      stall: { searchStall = {} },
    } = this.props;
    this.getAllBankList({ pageNo: 1, pageSize: 10, enableList: [true], ...searchStall });
    this.getMarketList(); //获得市场列表
  }

  // 获得市场列表
  getMarketList() {
    this.setState({ loading: true });
    this.props.marketList();
    this.setState({ loading: false });
  }

  // 获取省市区
  getAllDistrictInfo(params) {
    this.props.getAllDistrictInfo(params);
  }

  //商行分类勾选
  onStateChange = (value) => {
    this.setState({ pactStatus: value[0] });
  };

  // 获取查询参数
  getSearchParams = (fn) => {
    this.props.form.validateFields(
      [
        'cityCode',
        'marketId',
        'pactStartTime',
        'pactStatus',
        'name',
        'personName',
        'personPhone',
        'parentStallId',
        'referralStallId',
        'createTime',
        'systemTypeList',
        'typeList',
        'enableList',
        'saleTypeList',
        'id',
        'businessType',
      ],
      (errors, values) => {
        if (errors) {
          return;
        }

        values = {
          district: values.cityCode ? values.cityCode : null,
          marketId: values.marketId ? values.marketId : null,
          pactStartTimeStart:
            values['pactStartTime'] && values['pactStartTime'].length === 2
              ? moment(values['pactStartTime'][0].format(format1)).valueOf()
              : null,
          pactStartTimeEnd:
            values['pactStartTime'] && values['pactStartTime'].length === 2
              ? moment(values['pactStartTime'][1].format(format2)).valueOf()
              : null,
          pactStatus: values.pactStatus ? values.pactStatus : null,
          name: values.name ? values.name : null,
          personName: values.personName ? values.personName : null,
          personPhone: values.personPhone ? values.personPhone : null,
          parentStallId: values.parentStallId ? values.parentStallId : null,
          referralStallId: values.referralStallId ? values.referralStallId : null,
          createTimeStart:
            values['createTime'] && values['createTime'].length === 2
              ? moment(values['createTime'][0].format(format1)).valueOf()
              : null,
          createTimeEnd:
            values['createTime'] && values['createTime'].length === 2
              ? moment(values['createTime'][1].format(format2)).valueOf()
              : null,
          systemTypeList:
            values.systemTypeList && values.systemTypeList.length === 2
              ? []
              : values.systemTypeList || [],
          typeList: values.typeList || [],
          enableList:
            values.enableList && values.enableList.length === 2 ? [] : values.enableList || [],
          saleTypeList: values.saleTypeList || [],
          id: values.id || null,
          businessTypeList: values.businessType || [],
        };
        fn && fn(values);
      },
    );
  };

  // 查询
  handleSearch = (e) => {
    e.preventDefault();

    this.getSearchParams((values) => {
      this.getAllBankList({ ...values, pageNo: 1, pageSize: 10 });
    });
  };

  //分页
  setPageChange = (e) => {
    const {
      stall: { searchStall },
    } = this.props;
    this.getAllBankList({
      ...searchStall,
      pageNo: e.current,
      pageSize: e.pageSize,
    });
  };

  // 获取商行列表
  getAllBankList(params) {
    if (
      !params.systemTypeList ||
      params.systemTypeList.length === 0 ||
      params.systemTypeList.includes(2)
    ) {
      // 包含测试商行
      if (params.typeList && params.typeList.length > 0) {
        // 省心版测试
        if (!params.typeList.includes(3) && params.typeList.includes(1)) {
          params.typeList.push(3);
        }
        // 专业版测试
        if (!params.typeList.includes(4) && params.typeList.includes(2)) {
          params.typeList.push(4);
        }
      }
    }

    this.props.listAll(params);
  }

  // 省市区选择事件
  onCityChange = () => {};

  // 获取档主信息
  async getStallManger(record) {
    const { personPhone, id } = record;
    await this.props.getStallManger({ id: id });
    this.setState({
      visible: true,
      boothPhone: personPhone,
    });
  }

  //档主信息修改取消
  handleCancel = () => {
    this.setState({ visible: false });
  };

  //重置档主密码
  resetPwd(record) {
    const that = this;
    confirm({
      title: '是否重置档主密码为手机号后6位?',
      content: '',
      onOk() {
        const { personPhone } = record;
        that.restPassword({
          personPhone: personPhone,
        });
      },
      onCancel() {},
    });
  }

  //重置密码
  async restPassword(params) {
    await this.props.restPassword(params);
    message.success('重置成功');
  }

  //修改档主信息
  async updateStallIdea(params) {
    await this.props.updateStallManger(params);
    const {
      stall: { updateMangerInfoVo },
    } = this.props;
    if (updateMangerInfoVo && updateMangerInfoVo.code === 200) {
      message.info('修改成功！');
      this.handleReset(); //保存成功后重新刷新页面
    } else if (updateMangerInfoVo && updateMangerInfoVo.code && updateMangerInfoVo.code !== 200) {
      message.error(updateMangerInfoVo.msg);
    }
    this.setState({
      visible: false,
    });
  }

  // 修改档主信息
  updateStallManger = (e) => {
    e.preventDefault();
    const {
      stall: { stallMangerInfoVo },
    } = this.props;
    const { bdStallId } = stallMangerInfoVo;
    this.props.form.validateFields(['owner', 'ownerphone'], (errors, values) => {
      if (!!errors) {
        return;
      }
      this.updateStallIdea({ name: values.owner, phone: values.ownerphone, bdStallId: bdStallId });
    });
  };

  // 查看详情
  toDetail(record) {
    this.props.history.push({
      pathname: '/booth/stallManager/stallManagementDetail',
      state: { stallId: record.id },
    });
  }

  //新增，修改商行
  addStallManagement(record) {
    this.props.history.push({ pathname: '/booth/stallManager/NewStallManage', state: { record } });
  }

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.getAllBankList({ pageSize: 10, pageNo: 1, enableList: [true] });
  };

  //上传之前拦截下
  beforeUpload = (file) => {
    const str = file.name.split('.')[1];
    const isRightType = str === 'xls' || str === 'xlsx';
    const limitSize = file.size / 1024 / 1024 < 1;
    if (!isRightType) {
      message.error('仅支持Excel文件!', 3);
    }
    if (!limitSize) {
      message.error('文件大小不超过1M');
    }
    return limitSize;
  };

  //上传之后的改变事件
  handleChangeByUpload = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
      if (info.file.response.code === 200) {
        message.success(`${info.file.name} 上传成功!`, 5);
        // 刷新页面
        this.getAllBankList({ pageNo: 1, pageSize: 10 });
      } else if (info.file.response.code === 8009) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(info.file.response.msg, 5);
      }
    } else if (info.file.status === 'error') {
      if (info.file && info.file.response && info.file.response.msg) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(`${info.file.name} 上传失败`, 5);
      }
      this.setState({ loading: false });
    }
  };

  // 导出
  exportData = () => {
    this.getSearchParams(async (values) => {
      const response = await exportStalls({ ...values });
      exportFile(response, '商行列表.xlsx');
    });
  };

  render() {
    const {
      mergeSet: {
        districtInfo: { mapping },
      },
      stall: { allStallBankData = {}, stallMangerInfoVo, marketData = [], searchStall = {} },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { origins } = mapping || [];
    //去掉区
    if (origins instanceof Array) {
      for (const iterator of origins) {
        for (const iteratorChildren of iterator.children) {
          delete iteratorChildren.children;
        }
      }
    }
    let { name, phone } = stallMangerInfoVo; //修改档主信息默认携带
    const { pageList, pageNo = 1, totalSize = 1 } = allStallBankData;
    let dataSource = pageList || [];

    const pagination = {
      pageSize: 10,
      total: totalSize,
      current: pageNo,
    };
    const layout = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 };

    //上传合同或者设备用到的参数
    const uploadParams = {
      createUid: userId,
    };
    //上传合同的属性值
    const uploadContractProps = {
      name: 'file',
      action: `${API_ADMIN}stallPacts/upload?${generalParamsWithPToStr(
        'stallPacts/upload',
        uploadParams,
      )}`,
      headers: { authorization: 'authorization-text' },
      beforeUpload: this.beforeUpload,
      showUploadList: false,
      onChange: this.handleChangeByUpload,
    };
    //上传设备的属性值
    const uploadDeviceProps = {
      name: 'file',
      action: `${API_ADMIN}stallDevice/upload?${generalParamsWithPToStr(
        'stallDevice/upload',
        uploadParams,
      )}`,
      headers: { authorization: 'authorization-text' },
      beforeUpload: this.beforeUpload,
      showUploadList: false,
      onChange: this.handleChangeByUpload,
    };

    return (
      <PageHeaderLayout>
        <div className='StallManagement'>
          <Form onSubmit={this.handleSearch} className='ant-advanced-search-form'>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem label='城&emsp;&emsp;市'>
                  {getFieldDecorator('cityCode', {
                    initialValue: searchStall.district,
                    rules: [{ required: false, message: '' }],
                  })(
                    <TreeSelect
                      allowClear
                      showSearch
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={origins}
                      placeholder='请选择城市'
                      treeDefaultExpandAll={false}
                      onChange={this.onCityChange}
                      filterTreeNode={(input, treeNode) => {
                        return treeNode.props.title.indexOf(input) >= 0;
                      }}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='市场名称'>
                  {getFieldDecorator('marketId', {
                    initialValue: searchStall.marketId,
                    rules: [{ required: false, message: '请选择市场!' }],
                  })(
                    <Select
                      placeholder='请选择市场'
                      style={{ minWidth: '100%' }}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.isEdit ? true : false}
                    >
                      {marketData.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 300 }}>
                <FormItem label='签约日期'>
                  {getFieldDecorator('pactStartTime', {
                    initialValue: searchStall.pactStartTimeStart
                      ? [
                          moment(searchStall.pactStartTimeStart),
                          moment(searchStall.pactStartTimeEnd),
                        ]
                      : null,
                    rules: [{ required: false, message: '请选择时间' }],
                  })(
                    <RangePicker
                      format='YYYY-MM-DD'
                      placeholder={['开始时间', '结束时间']}
                    ></RangePicker>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 300 }}>
                <FormItem label='创建时间'>
                  {getFieldDecorator('createTime', {
                    initialValue: searchStall.createTimeStart
                      ? [moment(searchStall.createTimeStart), moment(searchStall.createTimeEnd)]
                      : null,
                    rules: [{ required: false, message: '请选择时间' }],
                  })(
                    <RangePicker
                      format='YYYY-MM-DD'
                      placeholder={['开始时间', '结束时间']}
                    ></RangePicker>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行ID'>
                  {getFieldDecorator('id', {
                    initialValue: searchStall.id,
                    rules: [
                      {
                        required: false,
                        message: '请输入商行ID',
                      },
                      {
                        pattern: /^\d+$/,
                        message: '只能输入数字!',
                      },
                    ],
                  })(<Input placeholder='请输入商行ID' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行名称'>
                  {getFieldDecorator('name', {
                    initialValue: searchStall.name,
                    rules: [{ required: false, message: '请输入商行名称!' }],
                  })(<Input placeholder='请输入商行名称' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='档&emsp;&emsp;主'>
                  {getFieldDecorator('personName', {
                    initialValue: searchStall.personName,
                    rules: [{ required: false, message: '' }],
                  })(<Input placeholder='请输入档主名称' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='档主电话'>
                  {getFieldDecorator('personPhone', {
                    initialValue: searchStall.personPhone,
                    rules: [{ required: false, message: '' }],
                  })(<Input placeholder='请输入号码' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='源头商行ID'>
                  {getFieldDecorator('parentStallId', {
                    initialValue: searchStall.parentStallId,
                    rules: [{ required: false, message: '' }],
                  })(<Input placeholder='请输源头商行ID' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='推荐商行ID'>
                  {getFieldDecorator('referralStallId', {
                    initialValue: searchStall.referralStallId,
                    rules: [{ required: false, message: '' }],
                  })(<Input placeholder='请输推荐商行ID' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='系统类型'>
                  {getFieldDecorator('systemTypeList', {
                    initialValue: searchStall.systemTypeList,
                  })(
                    <Checkbox.Group>
                      {[...STALL_SYSTEM_TYPE].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 450 }}>
                <FormItem label='商行版本'>
                  {getFieldDecorator('typeList', {
                    initialValue: searchStall.typeList,
                  })(
                    <Checkbox.Group>
                      {[...STALL_TYPE].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行状态'>
                  {getFieldDecorator('enableList', {
                    initialValue: searchStall.enableList || [true],
                  })(
                    <Checkbox.Group>
                      <Checkbox value={true}>启用</Checkbox>
                      <Checkbox value={false}>注销</Checkbox>
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行类型'>
                  {getFieldDecorator('businessType', {
                    initialValue: searchStall.businessTypeList || [],
                  })(
                    <Checkbox.Group>
                      {Object.keys(BUSINESS_TYPE).map((item) => (
                        <Checkbox value={Number(item)}>{BUSINESS_TYPE[item]}</Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 800 }}>
                <FormItem label='管理模式'>
                  {getFieldDecorator('saleTypeList', {
                    initialValue: searchStall.saleTypeList,
                  })(
                    <Checkbox.Group>
                      {[...STALL_SALE_TYPE].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 450 }}>
                <FormItem label='商行分类'>
                  {getFieldDecorator('pactStatus', {
                    initialValue: searchStall.pactStatus,
                    rules: [{ required: false, message: '' }],
                  })(
                    <Checkbox.Group onChange={this.onStateChange}>
                      {[...STALL_TYPES].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <FormItem>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: '18px' }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    type='primary'
                    style={{ marginLeft: '18px' }}
                    onClick={() => this.addStallManagement()}
                  >
                    新增商行
                  </Button>
                  {hasPermissionCode('01.003.005') ? (
                    <Button style={{ marginLeft: '18px' }} onClick={this.exportData}>
                      导出
                    </Button>
                  ) : null}
                  <Button
                    type='primary'
                    style={{ marginLeft: '18px' }}
                    target='_blank'
                    onClick={() =>
                      window.open(
                        `${API_ADMIN}stallPacts/downloadTemplet?${generalParamsWithPToStr(
                          'stallPacts/downloadTemplet',
                          {},
                        )}`,
                      )
                    }
                  >
                    <span>合同导入模板</span>
                    <span>下载</span>
                  </Button>
                  <Button
                    type='primary'
                    style={{ marginLeft: '18px' }}
                    target='_blank'
                    onClick={() =>
                      window.open(
                        `${API_ADMIN}stallDevice/downloadTemplet?${generalParamsWithPToStr(
                          'stallDevice/downloadTemplet',
                          {},
                        )}`,
                      )
                    }
                  >
                    <span>设备导入模板</span>
                    <span>下载</span>
                  </Button>
                  <Upload {...uploadContractProps}>
                    <Button
                      type='primary'
                      icon='upload'
                      target='_blank'
                      style={{ marginLeft: '18px' }}
                    >
                      合同批量导入
                    </Button>
                  </Upload>
                  <Upload {...uploadDeviceProps}>
                    <Button
                      type='primary'
                      icon='upload'
                      target='_blank'
                      style={{ marginLeft: '18px' }}
                    >
                      设备批量导入
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Table
            dataSource={dataSource}
            pagination={pagination}
            rowKey={(_, index) => index}
            align='center'
            scroll={{ x: 2000 }}
            onChange={this.setPageChange.bind(this)}
            loading={this.state.loading}
            style={{ whiteSpace: 'nowrap' }}
          >
            <Column title='商行ID' dataIndex='id' key='id' width={100} />
            <Column
              title='商行全名'
              dataIndex='name'
              key='name'
              width={200}
              render={(t) => {
                return <span className='txt'>{t}</span>;
              }}
            />
            <Column
              title='市场名'
              dataIndex='marketName'
              key='marketName'
              width={200}
              render={(t) => {
                return <span className='txt'>{t}</span>;
              }}
            />
            <Column
              title='归属地'
              dataIndex='district'
              key='district'
              width={200}
              render={(_, record) => {
                return `${record.provinceName || ''} ${record.cityName || ''} `;
              }}
            />
            <Column
              title='档主'
              dataIndex='personName'
              key='personName'
              width={120}
              render={(t) => {
                return <span className='txt'>{t}</span>;
              }}
            />
            <Column title='档主电话' dataIndex='personPhone' key='personPhone' width={120} />
            <Column
              title='入驻时间'
              dataIndex='createTime'
              key='createTime'
              width={200}
              render={(_, record) => {
                const { createTime } = record;
                return moment(createTime).format('YYYY-MM-DD HH:mm');
              }}
            />
            <Column title='昨日GMV' dataIndex='yesterdayGmv' key='yesterdayGmv' width={120} />
            <Column
              title='昨日单据量'
              dataIndex='yesterdaySaleOrderCount'
              key='yesterdaySaleOrderCount'
              width={120}
            />
            <Column
              title='设备数'
              dataIndex='printerConfigCount'
              key='printerConfigCount'
              width={100}
            />
            <Column title='账号数' dataIndex='userCount' key='userCount' width={100} />
            <Column
              title='商行分类'
              dataIndex='pactStatus'
              key='pactStatus'
              width={100}
              render={(text) => {
                return STALL_TYPES.get(text) || '';
              }}
            />
            <Column
              title='签约时间'
              dataIndex='pactStartTime'
              key='pactStartTime'
              width={200}
              render={(text) => {
                return (text && moment(text).format('YYYY-MM-DD HH:mm')) || '';
              }}
            />
            <Column
              title='系统类型'
              dataIndex='systemType'
              width={100}
              render={(text) => {
                return text ? STALL_SYSTEM_TYPE.get(text) : '';
              }}
            />
            <Column
              title='商行版本'
              dataIndex='type'
              width={100}
              render={(text) => {
                // 过渡期商行类型的处理 start
                if (text === 3) {
                  text = 1;
                } else if (text === 4) {
                  text = 2;
                }
                //过渡期商行类型的处理 end
                return text ? STALL_TYPE.get(text) : '';
              }}
            />
            <Column
              title='管理模式'
              dataIndex='saleType'
              width={250}
              render={(text) => {
                return text ? STALL_SALE_TYPE.get(text) : '';
              }}
            />
            <Column
              title='商行类型'
              dataIndex='businessType'
              width={100}
              render={(text) => {
                return BUSINESS_TYPE[text] || '蔬菜';
              }}
            />
            <Column
              title='商行状态'
              dataIndex='enable'
              width={150}
              render={(text) => {
                if (text) {
                  return (
                    <Tag color={'green'} key={'启用'}>
                      启用
                    </Tag>
                  );
                } else {
                  return (
                    <Tag color={'red'} key={'注销'}>
                      注销
                    </Tag>
                  );
                }
              }}
            />
            <Column
              title='源头商行ID'
              dataIndex='parentStallId'
              key='parentStallId'
              width={100}
              render={(text) => {
                return text || '';
              }}
            />
            <Column
              title='推荐商行ID'
              dataIndex='referralStallId'
              key='referralStallId'
              width={100}
              render={(text) => {
                return text || '';
              }}
            />
            <Column
              title='推荐商行市场'
              dataIndex='referralMarketName'
              key='referralMarketName'
              width={200}
              render={(t) => {
                return <span className='txt'>{t}</span>;
              }}
            />
            <Column
              title='推荐商行'
              dataIndex='referralStallName'
              key='referralStallName'
              width={200}
              render={(t) => {
                return <span className='txt'>{t}</span>;
              }}
            />
            <Column
              title='操作'
              dataIndex='operating'
              width={400}
              key='operating'
              fixed={'right'}
              render={(_, record) => {
                return (
                  <div className='buttonStyle'>
                    <a
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.toDetail(record);
                      }}
                    >
                      查看详情
                    </a>
                    <Divider type='vertical' />
                    <a
                      style={{ marginLeft: '10px' }}
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.addStallManagement(record);
                      }}
                    >
                      修改商行
                    </a>
                    <Divider type='vertical' />
                    <a
                      style={{ marginLeft: '10px' }}
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.getStallManger(record);
                      }}
                    >
                      修改档主信息
                    </a>
                    <Divider type='vertical' />
                    <a
                      style={{ marginLeft: '10px' }}
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.resetPwd(record);
                      }}
                    >
                      重置密码
                    </a>
                  </div>
                );
              }}
            />
          </Table>
          <Modal
            title='修改档主信息'
            visible={this.state.visible}
            onCancel={this.handleCancel}
            destroyOnClose={true}
            className='modalStyle'
            width='600px'
            footer={[]}
          >
            <div className='extraContent' style={{ width: '100%' }}>
              <Form onSubmit={this.updateStallManger}>
                <Row gutter={24}>
                  <Col span={24} style={{ paddingTop: '10px' }}>
                    <FormItem label='档主名称'>
                      {getFieldDecorator('owner', {
                        initialValue: name,
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: ' 请输入档主名称!',
                          },
                        ],
                      })(<Input placeholder='请输入档主名称' />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} style={{ paddingTop: '10px' }}>
                    <FormItem label='档主手机号'>
                      {getFieldDecorator('ownerphone', {
                        initialValue: phone,
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            pattern: /^1[3456789]\d{9}$/,
                            message: '请输入正确的电话号码!',
                          },
                        ],
                      })(
                        <Input
                          readOnly
                          onClick={() => {
                            message.warn('运营端不支持修改档主手机号');
                          }}
                          placeholder='请输入档主手机号'
                          style={{ width: '100%' }}
                        />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <div style={{ display: 'flex', height: '36px' }}>
                  <Button
                    style={{ float: 'right', marginRight: '5px', marginTop: '5px' }}
                    type='primary'
                    htmlType='submit'
                    ref='saveOrUpdate'
                  >
                    确定
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </PageHeaderLayout>
    );
  }
}
export default StallManagement;
