import request from '../../utils/request';

// 获取档口列表
export async function list(params) {
  return request({ url: 'stalls/list', params });
}

// 获取档口信息
export async function view(params) {
  return request({ url: 'stalls/view', params });
}
export async function colorList() {
  return request({ url: 'stalls/type/color/list', method: 'GET', headers: 'json' });
}

// 新增档口
export async function save(params) {
  return request({ url: 'stalls/save', params, headers: 'json' });
}

// 修改档口
export async function update(params) {
  return request({ url: 'stalls/update', params, headers: 'json' });
}

// 重置档主密码
export async function restPassword(params) {
  return request({ url: 'stalls/restPassword', params });
}

// 获取档主信息
export async function getStallManger(params) {
  return request({ url: 'stalls/getStallManger', params });
}

// 更新档主信息
export async function updateStallManger(params) {
  return request({ url: 'stalls/updateStallManger', params });
}

// 获取档口信息
export async function fakeStallMembers() {
  return request({ url: 'api/stallmembers' });
}
// 删除 end

// 获取档口的基本信息
export async function getStallInfo(params) {
  return request({ url: 'stall/info', params });
}

// 获取档口的收益数据
export async function getStallIncome(params) {
  return request({ url: 'stall/income', params });
}
// 获取档口的销售数据
export async function getStallSales(params) {
  return request({ url: 'stall/sales', params });
}

// 修改档口信息
export async function updateStall(params) {
  return request({ url: 'stalls/saveOrUpdateStalls', params });
}

// 获取档口的人员信息
export async function getStallEmployees(params) {
  return request({ url: 'user/getStallUserLst', params });
}

// 获取档口配置列表
export async function getAllConfig(params) {
  return request({ url: 'stallConfig/getAllConfig', params });
}

// 获取档口信息
export async function getConfig(params) {
  return request({ url: 'stallConfig/getConfig', params });
}

// 获取档口列表
export async function getAllStallsConfig(params) {
  return request({ url: 'stallConfig/getAllStalls', params });
}

// 修改档口保存
export async function saveOrUpdateGroupConfig(params) {
  return request({ url: 'stallConfig/saveOrUpdateGroupConfig', params });
}

// 档口信息删除
export async function stallDelete(params) {
  return request({ url: 'stallConfig/delete', params });
}

// 档口信息删除
export async function updateGroupConfig(params) {
  return request({ url: 'stallConfig/updateGroupConfig', params });
}

// 获取档口配置分组列表
export async function getAllGroup(params) {
  return request({ url: 'stallGroup/getAllGroup', params, method: 'get', headers: 'json' });
}
// 获取配置分组列表
export async function saveOrUpdateConfig(params) {
  return request({ url: 'stallConfig/saveOrUpdateConfig', params });
}
// 获取档口配置
export async function getGroupById(params) {
  return request({ url: 'stallGroup/getGroupById', params });
}
// 获取档口去重后的配置分组列表
export async function getAllDistinctGroup(params) {
  return request({ url: 'stallGroup/getAllDistinctGroup', params });
}
// 获取档口分组列表
export async function saveOrUpdateGroup(params) {
  return request({ url: 'stallGroup/saveOrUpdateGroup', params });
}
// 获取档口配置
export async function getStallGroupById(params) {
  return request({ url: 'stallGroup/getStallGroupById', params });
}
// 获取所有配置
export async function getAllSysConfigs(params) {
  return request({ url: 'stallConfig/getAllSysConfigs', params });
}
// 获取配置列表
export async function getAllSysConfigPage(params) {
  return request({ url: 'stallConfig/getAllSysConfigPage', params });
}
// 获取配置列表
export async function saveOrUpdateStallGroup(params) {
  return request({ url: 'stallGroup/saveOrUpdateStallGroup', params });
}

// 获取市场列表
export async function marketList(params) {
  return request({ url: 'market/marketList', params });
}

// 新版获取市场列表
export async function getMarketList(params) {
  return request({ url: 'market/marketSimpleList', params });
}

/**商行管理部分*/

// 获取商行列表
export async function listAll(params) {
  return request({ url: 'stalls/list', params });
}
//商行单据统计
export async function boothStaticAll(params) {
  return request({ url: 'stallStatistic/list', params, headers: 'json' });
}
// 市场健康月统计
// 商行数据分析列表
export async function getStallAnalyseData(params) {
  return request({ url: 'stallAnalyseDataResource/getStallAnalyseData', params, headers: 'json' });
}
//获取打印机关联商行信息
export async function searchPrinter(params) {
  return request({ url: 'stalls/getPrinterRelateStall', params });
}
//商行管理 -- 查看详情
export async function getStallDetails(params) {
  return request({ url: 'stalls/details', params });
}
//获取档口的数据统计
export async function getStatistical(params) {
  return request({ url: 'stalls/statistical', params });
}
//获取所有档口的数据统计
export async function getAllStatistical(params) {
  return request({ url: 'stalls/all/statistical', params });
}
//转换系统类型，简介：修改商行type类型
export async function updateStallsType(params) {
  return request({ url: 'stalls/updateStallsType', params });
}

/** 商行管理-查看详情-设备管理  开始 */
//设备管理列表
export async function getListDevices(params) {
  return request({ url: 'stallDevice/listDevices', params });
}
/** 商行管理-查看详情-设备管理 结束 */

/** 商行管理-查看详情-合同管理  开始 */
//合同管理列表
export async function getListPacts(params) {
  return request({ url: 'stallPacts/listPacts', params });
}

//新增合同
export async function addPact(params) {
  return request({ url: 'stallPacts/addPact', params, method: 'post', headers: 'json' });
}
//修改合同
export async function updatePact(params) {
  return request({ url: 'stallPacts/updatePact', params, method: 'post', headers: 'json' });
}
//手动失效合同
export async function enablePact(params) {
  return request({ url: 'stallPacts/enablePact', params });
}
/** 商行管理-合同管理 结束 */

//获取商行下所有员工信息
export async function getAllStallEmploy(params) {
  return request({ url: 'stalls/getAllStallEmploy', params });
}
//档主权限移交
export async function transformStallsManager(params) {
  return request({ url: 'stalls/transformStallsManager', params });
}
//启用或者注销商行
export async function enableMarket(params) {
  return request({ url: 'stalls/enable', params });
}
//注销登录
export async function loginOutAll(params) {
  return request({ url: 'user/loginOutAll/' + params.stallId, headers: 'json' });
}

//固化库存
export async function solidifyStock(stallId) {
  return request({
    url: `ProductPurchaseStuffStockResource/generatePeriodStock/${stallId}`,
    method: 'get',
    headers: 'json',
  });
}

// 转换售卖方式
export async function converSaleMode(params) {
  return request({ url: '/stalls/changeStallSaleType', params, headers: 'json' });
}

//获取筐子列表
export async function getListBasket(params) {
  return request({ url: 'basket/list', params, method: 'get', headers: 'json' });
}
// 筐子功能是否开启
export async function configBasket(params) {
  return request({ url: 'basket/isStallOpenBasket', params, method: 'get', headers: 'json' });
}
//新增筐子
export async function addBasket(params) {
  return request({ url: 'basket/addBasket', params, headers: 'json' });
}
//更新筐子
export async function updateBasket(params) {
  return request({ url: 'basket/updateBasket', params, headers: 'json' });
}

//启用/禁用筐子
export async function activeBasket(params) {
  return request({ url: 'basket/activeBasket', params, headers: 'json' });
}
//结算费用列表
export async function settlementFeeList({ stallId }) {
  return request({ url: `cxmSettlementRule/list/${stallId}`, method: 'get', headers: 'json' });
}
//结算费用类型
export async function settlementFeeType(params) {
  return request({ url: 'cxmDictData/getDictDataFinance', params, method: 'get', headers: 'json' });
}
// 新增结算
export async function addSettleFee(params) {
  return request({ url: 'cxmSettlementRule/add', params, headers: 'json' });
}
// 修改结算
export async function updateSettleFee(params) {
  return request({ url: 'cxmSettlementRule/update', params, headers: 'json' });
}
// 结算详情
export async function settleFeeDetail({ id }) {
  return request({ url: `cxmSettlementRule/view/${id}`, method: 'get', headers: 'json' });
}

// 签字额度 列表
export async function getSignList(pageNo, params) {
  return request({
    url: `stall/business/sign/list/${pageNo}/10`,
    params,
    method: 'get',
    headers: 'json',
  });
}
// 签字额度 新增
export async function addSign(params) {
  return request({ url: `stall/business/sign/add/`, params, method: 'post', headers: 'json' });
}

// 获取商行白名单
export async function getStallWhiteList(params) {
  return request({ url: 'stallWhite/queryStallWhite', params });
}

// 添加白名单商行
export async function addStallWhite(params) {
  return request({ url: 'stallWhite/addStallWhite', params });
}

// 移除商行白名单
export async function removeStallWhite(params) {
  return request({ url: 'stallWhite/removeStallWhite', params });
}

// 一键下线
export async function offineAllStall(params) {
  return request({ url: 'stallWhite/offlineStall', params });
}

// 附加费用列表
export async function addtionFeeList(params) {
  return request({ url: 'addtionalFee/list', params });
}

// 更新附加费商行展示明字
export async function updateAddtionFee(params) {
  return request({ url: 'addtionalFee/update', params });
}

// 更新附加费的展示状态
export async function updateAddtionFeeEnable(params) {
  return request({ url: 'addtionalFee/enable', params });
}

/**
 * 老档主白名单和下面应用一样的接口，但是参数似乎不一致
 * @param {*} params
 */

// 获取手机收银端商行白名单
export async function fetchMobileCashAppList(params) {
  return request({ url: 'stallWhite/queryStallWhite', params });
}

// 添加手机收银端白名单商行
export async function addMobileCashAppStall(params) {
  return request({ url: 'stallWhite/addStallWhite', params });
}

// 移除手机收银端商行白名单
export async function removeMobileCashAppStall(params) {
  return request({ url: 'stallWhite/removeStallWhite', params });
}

// 手机收银端一键下线
export async function removeAllMobileCashAppStall(params) {
  return request({ url: 'stallWhite/offlineStall', params });
}

/**
 * 商行单据统计 导出
 * @param {objcet} params
 * @returns
 */
export async function exportStatistic(params) {
  return request({
    url: `stallStatistic/exportStatistic`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}
/**
 * 商行分析数据导出 导出
 * @param {objcet} params
 * @returns
 */
export async function exportStallAnalyseData(params) {
  return request({
    url: `stallAnalyseDataResource/exportStallAnalyseData`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

/**
 * 市场统计数据
 * @param {objcet} params
 * @returns
 */
export async function marketStallStatistic(params) {
  return request({ url: 'stallStatistic/marketStallStatistic', params, headers: 'json' });
}

/**
 * 商行活跃信息
 * @param {objcet} params
 * @returns
 */
export async function stallInfos(params) {
  return request({ url: 'stallStatistic/stallInfos', params, headers: 'json' });
}

/**
 * 市场统计数据 导出
 * @param {objcet} params
 * @returns
 */
export async function exportMarketStallStatistic(params) {
  return request({
    url: `stallStatistic/exportMarketStallStatistic`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

// 删除商行档口分组
export async function deleteStallGroup(params) {
  let p = {};
  return request({ url: `stallGroup/group/${params.groupNo}/${params.stallId}`, p });
}

// 获取所有配置
export async function getStallGroupConfigs() {
  return request({ url: `stallGroup/groups`, method: 'get', headers: 'json' });
}

// 获取商行档口分组
export async function getStallGroup(params) {
  return request({ url: `stallGroup/stallGroups`, params, headers: 'json' });
}

// 获取商行员工
export async function getStallEmploys(stallId, params) {
  return request({ url: `stalls/${stallId}/employs`, params, method: 'get', headers: 'json' });
}

// 获取商行自定义角色列表
export async function getStallRoleList(stallId) {
  return request({ url: `role/${stallId}/roles`, method: 'get', headers: 'json' });
}

// 获取商行自定义角色详情
export async function getStallRoleDetail(roleId) {
  return request({ url: `role/view/${roleId}`, method: 'get', headers: 'json' });
}

// 新增商行自定义角色
export async function addRole(params) {
  return request({ url: `role/add`, params, headers: 'json' });
}

// 修改商行自定义角色
export async function updateRole(params) {
  return request({ url: `role/update`, params, headers: 'json' });
}

// 删除商行自定义角色
export async function deleteRole(params) {
  return request({ url: `role/delete`, params, headers: 'json' });
}

// 获取系统角色权限列表
export async function getSystemRoleCode(roleCode) {
  return request({ url: `role/getSystemRoleCode/${roleCode}`, method: 'get', headers: 'json' });
}

// 获取系统权限列表
export async function getPermissionList() {
  return request({ url: `role/getPermissionList`, method: 'get', headers: 'json' });
}

// 扩展支付方式 列表
export async function getPayMethodConfigList(stallId) {
  return request({ url: `payMethodConfig/list/${stallId}`, method: 'get', headers: 'json' });
}

// 扩展支付方式 编辑
export async function getPayMethodConfigUpdate(params) {
  return request({ url: `payMethodConfig/update`, params, method: 'post', headers: 'json' });
}

// 扩展支付方式 停用/启用
export async function getPayMethodConfigEnable(params) {
  return request({ url: `payMethodConfig/enable`, params, method: 'post', headers: 'json' });
}
// 获取商行推荐列表
export async function getReferralStallList(referralStallId) {
  return request({
    url: `stalls/referral/list/${referralStallId}`,
    method: 'get',
    headers: 'json',
  });
}

// 商行推荐奖励兑换
export async function referralExchange(params) {
  return request({ url: `stalls/referral/exchange`, params, method: 'post', headers: 'json' });
}

// 商行推荐奖励一键兑换
export async function referralExchangeAll(params) {
  return request({ url: `stalls/referral/allExchange`, params, method: 'post', headers: 'json' });
}

// 导出商行列表
export async function exportStalls(params) {
  return request({
    url: `stalls/export`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

// 商行员工管理-重置开票批次
export async function employResetBatch(params) {
  return request({ url: `stalls/resetBatch`, params, method: 'post', headers: 'json' });
}

// 查询账号列表
export async function getSupplierAppList() {
  return request({
    url: `supplierApp/list`,
    method: 'get',
    headers: 'json',
  });
}

// 移除账号
export async function removeSupplierAppAccount(phone) {
  return request({
    url: `supplierApp/remove?phone=` + phone,
    method: 'get',
    headers: 'json',
  });
}

// 复制商行
export async function copyStall(params) {
  return request({
    url: 'stalls/reopen',
    method: 'post',
    params,
    headers: 'json',
  });
}
