//会员管理-- 会员管理（查看详情）
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Tabs, Row, Col, Icon } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { PRODUCT_TYPE_LIST, STALL_TYPE } from '../../utils/deviceType';

import TestStall from '../../common/images/icon/testStall.png';
import PreStall from '../../common/images/icon/preStall.png';
import './index.less';

import { stallDetails } from '../../state/action/stall';

import OrderDetail from './Detail/orderDetail';
import AddOrderModal from './Detail/addOrderModal';

const { TabPane } = Tabs;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { stallDetails },
)
@Form.create()
class MemberManagementDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.record = (this.props.location.state && this.props.location.state.record) || {};
  }

  componentDidMount() {
    this.getStallDetail({ id: this.record.stallId }); // 查看商行详情
  }

  // 查看商行详情
  async getStallDetail(params) {
    await this.props.stallDetails(params);
  }

  back = (e) => {
    e.preventDefault();
    this.props.history.push({ pathname: '/booth/memberManagement', state: {} });
  };
  handleCancelForConvertType = () => {
    this.setState({ convertTypeVisible: false });
  };
  //刷新会员续费列表
  getChildrenMsg = (_, msg) => {
    this.setState({
      childrenMsg: msg,
    });
  };

  //定义订单详情页的ref
  onRef = (ref) => {
    this.orderDetailRef = ref;
  };

  //新增续费时 刷新详情页 以及续费列表
  refreshOrderDetail = (stallId) => {
    this.orderDetailRef.listVipPay({ pageNo: 1, pageSize: 10, stallId });
    this.props.stallDetails({ id: stallId });
  };

  render() {
    const {
      stall: { stallDetails = {} },
    } = this.props;
    const { member = {} } = stallDetails;
    const layout = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

    return (
      <PageHeaderLayout>
        <div className='memberManagementDetail'>
          <Row className='backRow'>
            <a href='/' onClick={this.back}>
              <Icon type='left' /> 返回
            </a>
          </Row>
          <Row className='row'>
            <Col {...{ xs: 24, sm: 24, md: 24, lg: 21, xl: 21 }}>
              <Row>
                <Col span={15} className='title'>
                  商行ID：{stallDetails.id || '- -'}
                </Col>
                <Col className='titleAddBtn'>
                  {/* 新增续费 */}
                  <AddOrderModal
                    stallDetails={stallDetails}
                    refreshOrderDetail={this.refreshOrderDetail}
                    getStallDetail={this.getStallDetail}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={16} className='title'>
                  商行名称：
                  {stallDetails.name || '- -'}
                  <span className='color1'>（{stallDetails.shortName || '- -'}）</span>
                </Col>
              </Row>
              <Row>
                <Col {...layout} className='colLabel'>
                  联系人：
                  <span className='color2'>{stallDetails.personName}</span>
                </Col>
                <Col {...layout} className='colLabel'>
                  联系电话：
                  <span className='color2'>{stallDetails.phone || '- -'}</span>
                </Col>
                <Col {...layout} className='colLabel'>
                  创建时间：
                  <span className='color2'>
                    {stallDetails.createTime
                      ? moment(stallDetails.createTime).format(dateFormat)
                      : '- -'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col {...layout} className='colLabel'>
                  商行版本：
                  <span className='color2'>{STALL_TYPE.get(stallDetails.type) || '- -'}</span>
                </Col>
                <Col {...layout} className='colLabel'>
                  当前会员服务：
                  <span className='color2'>
                    {PRODUCT_TYPE_LIST.get(member.productType) || '- -'}
                  </span>
                </Col>
                <Col {...layout} className='colLabel'>
                  有效期至：
                  <span className='color2'>
                    {member.expireTime ? moment(member.expireTime).format(dateFormat) : '- -'}
                  </span>
                  （剩余{member.productDuration || '0'}天）
                </Col>
              </Row>
              <Row>
                <Col {...layout} className='colLabel'>
                  会员状态：
                  <span className='color2'>
                    {' '}
                    {member.memberStatusDesc ? member.memberStatusDesc : '- -'}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 24, lg: 3, xl: 3 }}>
              {stallDetails.type === 1 || stallDetails.type === 2 ? (
                <img src={PreStall} alt='' style={{ width: 150 }} />
              ) : stallDetails.type === 3 || stallDetails.type === 4 ? (
                <img src={TestStall} alt='' style={{ width: 150 }} />
              ) : null}
            </Col>
          </Row>
          <Row className='row'>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='订单详情' key='1'>
                <OrderDetail stallId={this.record.stallId} onRef={this.onRef} />
              </TabPane>
            </Tabs>
          </Row>
        </div>
      </PageHeaderLayout>
    );
  }
}
export default MemberManagementDetail;
