import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Form,
  Card,
  Row,
  Col,
  Button,
  Select,
  Table,
  Modal,
  message,
  InputNumber,
  Popconfirm,
  Divider,
  Upload,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  list,
  fetchAllGroup,
  getStallGroupById,
  fetchAllStallsConfig2,
  marketList,
  saveOrUpdateStallGroup,
  stallDelete,
} from '../../state/action/stall';
import { deleteStallGroup } from '@/state/request/stall';

import '../booth-management/index.less';
import { isEmptyObject } from '../../utils/reg';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';
import { getAuthorityUserInfo } from '../../utils/authority';

const FormItem = Form.Item;
const Option = Select.Option;
const { Column } = Table;

@connect(
  (state) => {
    return { stall: state.stall, batch: state.batch };
  },
  {
    list,
    fetchAllGroup,
    getStallGroupById,
    fetchAllStallsConfig2,
    marketList,
    saveOrUpdateStallGroup,
    stallDelete,
  },
)
@Form.create()
class BoothGroup extends PureComponent {
  componentDidMount() {
    const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    this.fetchAllGroup({
      pageNo: 1,
      pageSize: 10,
      groupNo: groupNo,
    });
    this.fetchAllMarkets({});
    this.props.list();
  }

  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      visibleModal: false,
      value: 1,
      id: '',
      type: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
  }

  async fetchAllGroup(params) {
    await this.props.fetchAllGroup(params);
  }

  // 获取档口修改数据
  async fetchConfig(params) {
    await this.props.getStallGroupById(params);
  }

  // 获取档口列表
  async fetchAllStallsGroupInfos2(params) {
    await this.props.fetchAllStallsConfig2(params);
  }

  // 获取档口列表
  async fetchAllMarkets(params) {
    await this.props.marketList(params);
  }

  // 修改档口配置
  async saveOrUpdate(params) {
    await this.props.saveOrUpdateStallGroup(params);
    this.submit();
  }

  // 删除档口配置
  async stallDelete(params) {
    await this.props.stallDelete(params);
    this.delete();
  }

  // 分页和查询请求
  handleOnQuery = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;

      let params = {};
      const stallName = fieldsValue.stallName || '';
      const systemType = fieldsValue.systemType;
      if (fieldsValue.systemType !== '0') {
        params.systemType = systemType;
      }
      this.fetchAllGroup({
        ...params,
        id: this.state.id,
        groupNo: groupNo,
        stallName: stallName,
        pageNo: 1,
        pageSize: 10,
      });
    });
  };

  // 商行选择onchange事件
  onChange = (value) => {
    this.setState({
      selectValue: value,
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
      let params = {};
      const stallName = fieldsValue.stallName || '';
      const systemType = fieldsValue.systemType;
      if (fieldsValue.systemType !== '0') {
        params.systemType = systemType;
      }

      this.fetchAllGroup({
        ...params,
        groupNo: groupNo,
        stallName: stallName,
        pageNo: obj.current,
        pageSize: obj.pageSize,
      });
    });
  };

  // modal关闭事件
  handleCancel(e) {
    this.setState({
      visibleModal: false,
    });
  }

  // 修改数据
  modify(e, id) {
    e.preventDefault();
    this.fetchConfig({ id: id });
    this.setState({
      visibleModal: true,
      type: 'modify',
    });
  }

  // 删除档口数据
  deleteMessage(id) {
    this.stallDelete({
      id: id,
    });
  }

  // 启用/禁用档口分组
  updGroup(e, id, enable) {
    e.preventDefault();
    this.saveOrUpdate({
      id: id,
      enable: enable,
    });
  }

  // 删除档口分组
  async deleteGroup(e, groupNo, stallId) {
    e.preventDefault();
    const resData = await deleteStallGroup({ groupNo, stallId });
    const msg = '移除';
    if (resData.code === 200) {
      message.success(`${msg}成功`);
    } else if (resData.msg) {
      message.error(resData.msg);
    } else {
      message.error(`${msg}失败`);
    }
    this.handleOnQuery();
  }

  // 修改信息的回调
  submit() {
    const {
      stall: { resData },
    } = this.props;
    const msg = '操作';

    if (resData.code === 200) {
      message.success(`${msg}成功`);
    } else if (resData.msg) {
      message.error(resData.msg);
    } else {
      message.error(`${msg}失败`);
    }
    this.handleOnQuery();
  }

  // 删除档口信息回调
  delete() {
    const {
      stall: { resData },
    } = this.props;

    if (resData === 200) {
      message.info('删除成功');
    } else {
      message.info('删除失败');
    }
    this.handleOnQuery();
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      if (err) {
        return;
      }
      const { stallId, marketId } = fieldsValue;
      const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
      this.saveOrUpdate({
        id: this.state.type === 'add' ? null : this.state.id,
        groupNo: groupNo,
        submitStallId: stallId,
        marketId: marketId,
      });

      this.setState({
        visibleModal: false,
      });
    });
  };

  // modal radio 单选事件
  selectRadio = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  // 新增档口配置
  add() {
    this.setState({
      visibleModal: true,
      type: 'add',
      id: null,
    });
  }
  //返回到档口分组配置
  toBoothAllGroup() {
    this.props.history.push('/booth/boothAllGroup');
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      selectValue: '',
    });
    const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    this.fetchAllGroup({
      id: this.state.id,
      groupNo: groupNo,
      stallName: '',
      pageNo: 1,
      pageSize: 10,
    });
  };

  handleChange(value) {
    if (parseInt(value) === 0) {
      value = null;
    }
    this.fetchAllStallsGroupInfos2({ flag: 1, marketId: value });
  }

  //上传之前拦截下
  beforeUpload = (file) => {
    const str = file.name.split('.')[1];
    const isRightType = str === 'xls' || str === 'xlsx';
    const limitSize = file.size / 1024 / 1024 < 1;
    if (!isRightType) {
      message.error('仅支持Excel文件!', 3);
    }
    if (!limitSize) {
      message.error('文件大小不超过1M');
    }
    return limitSize;
  };
  //上传之后的改变事件
  handleChangeByUpload = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
      if (info.file.response.code === 200) {
        message.success(`${info.file.name} 上传成功!`, 5);
        // 刷新页面
        const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
        this.fetchAllGroup({
          groupNo: groupNo,
          pageNo: 1,
          pageSize: 10,
        });
      } else if (info.file.response.code === 8009) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(info.file.response.msg, 5);
      }
    } else if (info.file.status === 'error') {
      if (info.file && info.file.response && info.file.response.msg) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(`${info.file.name} 上传失败`, 5);
      }
      this.setState({ loading: false });
    }
  };

  render() {
    let {
      stall: { groupInfo, allStallsGroupData = {}, marketData = [], allStallsData = [] },
    } = this.props;

    const { pageList = [], pageNo, pageSize, totalPage, totalSize = 0 } = allStallsGroupData;

    const { getFieldDecorator } = this.props.form;

    let data = {};
    if (groupInfo !== undefined && groupInfo !== null && this.state.type !== 'add') {
      data = groupInfo;
      this.setState({
        id: data.id,
      });
    }
    let { groupNo, type, stallId, marketId } = data;
    if (groupNo === undefined || groupNo === null) {
      groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    }
    if (type === undefined || type === null) {
      type = '';
    }
    if (stallId === undefined || stallId === null) {
      stallId = '';
    }
    if (marketId === undefined || marketId === null) {
      marketId = '';
    }

    if (marketData !== null && marketData !== undefined && marketData.length) {
      const tmpMarketData = [];
      for (let i = 0; i < marketData.length; i++) {
        tmpMarketData.push(marketData[i]);
      }
      marketData = tmpMarketData;
    } else {
      marketData = [];
    }

    if (allStallsData !== null && allStallsData !== undefined && allStallsData.length) {
      const tmpallStallsData = [];
      for (let i = 0; i < allStallsData.length; i++) {
        tmpallStallsData.push(allStallsData[i]);
      }
      allStallsData = tmpallStallsData;
    } else {
      allStallsData = [];
    }

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 6,
      style: { marginBottom: 12 },
    };

    let userinfo = getAuthorityUserInfo();
    let hash = !isEmptyObject(userinfo) ? userinfo.access_token : '';
    const uploadContractProps = {
      name: 'file',
      action: `${API_ADMIN}stallGroup/importStall/${groupNo}`,
      headers: {
        authorization: 'authorization-text',
        accessToken: hash,
        appType: 'admin',
      },
      beforeUpload: this.beforeUpload,
      showUploadList: false,
      onChange: this.handleChangeByUpload,
    };

    const extraContent = (
      <div className={'searchModule'}>
        <Form
          onSubmit={this.handleOnQuery}
          className='login-form'
          style={{ marginBottom: '12px', float: 'left', width: '100%' }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label='商行名称'>
                {getFieldDecorator('stallName', {
                  rules: [{ required: false, message: '请输入商行名称!' }],
                })(<Input placeholder='请输入商行名称' allowClear />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='系统类型' name='systemType'>
                {getFieldDecorator('systemType', {
                  initialValue: '0',
                })(
                  <Select style={{ width: 180 }} placeholder='请选择系统类型'>
                    <Option key={'0'} value={'0'}>
                      全部
                    </Option>
                    <Option key={'1'} value={'1'}>
                      正式商行
                    </Option>
                    <Option key={'2'} value={'2'}>
                      测试商行
                    </Option>
                  </Select>,
                )}
              </FormItem>
            </Col>

            <Col span={24}>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button style={{ marginLeft: '5px' }} onClick={this.handleReset}>
                重置
              </Button>
              <Button type='primary' style={{ marginLeft: '5px' }} onClick={this.add.bind(this)}>
                新增
              </Button>
              <Button style={{ marginLeft: '5px' }} onClick={this.toBoothAllGroup.bind(this)}>
                返回档口分组
              </Button>
              <Button
                onClick={() =>
                  window.open(
                    `${API_ADMIN}versionRefer/downloadTemplet?${generalParamsWithPToStr(
                      'versionRefer/downloadTemplet',
                      {},
                    )}`,
                  )
                }
              >
                模版下载
              </Button>
              <Upload {...uploadContractProps}>
                <Button type='primary' icon='upload' target='_blank' style={{ marginLeft: '18px' }}>
                  批量导入
                </Button>
              </Upload>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const pagination = {
      pageSize: 10,
      total: totalSize,
    };

    return (
      <div className='booth-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                dataSource={pageList}
                pagination={pagination}
                rowKey='id'
                bordered
                onChange={this.pageSwitch.bind(this)}
              >
                <Column title='分组编号' dataIndex='groupNo' key='groupNo' />
                <Column title='档口号' dataIndex='stallId' key='stallId' />
                <Column title='档口名称' dataIndex='stallName' key='stallName' />
                <Column title='市场名称' dataIndex='marketName' key='marketName' />
                <Column
                  title='是否启用'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    const txt = enable ? '启用' : '禁用';
                    return <span>{txt}</span>;
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { groupNo, stallId } = record;
                    return (
                      <div>
                        <Popconfirm
                          title='是否确定移除'
                          onConfirm={(e) => this.deleteGroup(e, groupNo, stallId)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/'>
                            <span>移除</span>
                          </a>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              <Modal
                title={this.state.type === 'add' ? '新增档口分组' : '修改档口分组'}
                visible={this.state.visibleModal}
                footer={null}
                onCancel={this.handleCancel}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form key={this.state.id || 0} onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label='分组编号'>
                      {getFieldDecorator('groupNo', {
                        initialValue: groupNo,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入分组编号!',
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ paddingLeft: '0px', width: '100%' }}
                          readOnly
                          placeholder='请输入分组编号'
                        />,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='市场'>
                      {getFieldDecorator('marketId', {
                        initialValue: marketId,
                        rules: [
                          {
                            required: true,
                            message: ' 请选择市场!',
                          },
                        ],
                      })(
                        <Select
                          placeholder='请选择市场'
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={this.handleChange.bind(this)}
                          style={{ width: '100%' }}
                        >
                          {marketData.map((item) => (
                            <Option key={item.id}>{item.name}</Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='档口'>
                      {getFieldDecorator('stallId', {
                        initialValue: stallId,
                        rules: [
                          {
                            required: true,
                            message: ' 请选择档口!',
                          },
                        ],
                      })(
                        <Select
                          placeholder='请选择档口'
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: '100%' }}
                        >
                          {allStallsData.map((item) => (
                            <Option key={item.id}>{item.name}</Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem>
                      <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BoothGroup;
