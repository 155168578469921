import request from '../../../../../utils/request';

// 支付柜台配置

// 新增
export async function addDictDataPay(params) {
  return request({ url: 'cxmDictData/addDictDataPay', params, headers: 'json' });
}
// 修改
export async function updateDictDataPay(params) {
  return request({ url: 'cxmDictData/updateDictDataPay', params, headers: 'json' });
}
// 删除
export async function deleteDictDataPay(params) {
  return request({ url: 'cxmDictData/deleteDictDataPay', params, headers: 'json' });
}
// 查询
export async function getDictDataPay({ stallId }) {
  return request({
    url: `cxmDictData/getDictDataPay?stallId=${stallId}`,
    method: 'get',
    headers: 'json',
  });
}

// 查询
export async function getSplitAccountRule() {
  return request({ url: `cxmDictData/getSplitAccountRule`, method: 'get', headers: 'json' });
}
