// 商行管理 -- 商行管理（查看详情--转换商行版本)
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Row, Col, Radio, Select } from 'antd';

import './device.less';
import { updateStallsType } from '../../../../state/action/stall';

const { confirm } = Modal;

const FormItem = Form.Item;

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { updateStallsType },
)
@Form.create()
class ConvertType extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props.convertTypeVisible !== nextProps.convertTypeVisible) {
      this.props.form.setFieldsValue({ type: nextProps.type });
    }
  }

  //转换类型
  convertType(type) {
    if (type === 1 || type === 3) {
      return '省心版';
    } else if (type === 2 || type === 4) {
      return '专业版';
    } else {
      return '';
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['type'], (errors, values) => {
      if (!!errors) {
        return;
      }

      const { id, type, handleCancelForConvertType, handleDetail } = this.props;
      const that = this;
      if (this.convertType(type) === this.convertType(values.type)) {
        //一样的就不需要做任何操作
        handleCancelForConvertType();
        return;
      } else {
        if (type === 1 || type === 3) {
          //省心版转专业版
          confirm({
            title: '转换商行版本',
            content: (
              <div>
                <p>省心版转换为专业版后需进行商行实施配置后才能使用</p>
                <p></p>
                <p>确定要转换吗？</p>
              </div>
            ),
            async onOk() {
              that.setState({ visible: true });
            },
            onCancel() {
              handleCancelForConvertType();
            },
          });
        } else {
          //专业版可以转省心版
          confirm({
            title: '转换商行版本',
            content: (
              <div>
                <p>专业版转换为省心版后将禁用用户登陆POS机端，并切换为省心版计算逻辑</p>

                <p>确定要转换吗？</p>
                <p></p>
                <p style={{ color: 'red' }}>请注意：转换前需在POS上售罄所有在售批次！</p>
              </div>
            ),
            async onOk() {
              //修改类型
              //目前只能专业版转省心版，2->1；4->3
              await that.props.updateStallsType({
                id,
                type: 1, //type === 4 ? 3 : 1,
              });
              const {
                stall: { updateStallsType },
              } = that.props;
              if (updateStallsType.code === 200) {
                //刷新数据
                handleDetail();
                handleCancelForConvertType();
              } else {
              }
            },
            onCancel() {
              handleCancelForConvertType();
            },
          });
        }
      }
    });
  };
  handleCancel = (e) => {
    this.setState({ visible: false });
    this.props.handleCancelForConvertType();
  };
  //省心版转专业版
  handleSubmit2 = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['marketId'], async (errors, values) => {
      if (!!errors) {
        return;
      }

      const {
        id, //type,
        handleCancelForConvertType,
        handleDetail,
      } = this.props;
      const that = this;

      //省心版转专业版，1->2；3->4
      await this.props.updateStallsType({
        id,
        type: 2, //type === 3 ? 4 : 2,
        marketId: values.marketId,
      });
      const {
        stall: { updateStallsType },
      } = that.props;
      if (updateStallsType.code === 200) {
        //刷新数据
        handleDetail();
        handleCancelForConvertType();
        this.setState({ visible: false });
      } else {
      }
    });
  };

  render() {
    const { type, convertTypeVisible, handleCancelForConvertType, marketData, stallDetails } =
      this.props;
    const { visible } = this.state;

    const { getFieldDecorator } = this.props.form;
    const layout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { offset: 8, span: 16 },
      },
    };
    return (
      <div className='device'>
        <Modal
          title={`转换商行版本`}
          visible={convertTypeVisible}
          // onOk={this.handleOk}
          onCancel={handleCancelForConvertType}
          footer={null}
          width='600px'
        >
          <Form onSubmit={this.handleSubmit} labelAlign='left'>
            <Row gutter={24}>
              <Col span={12}>
                {/* 1:免费用户-正式商行
                                    2:收费用户-正式商行
                                    3:免费用户-测试商行
                                    4:收费用户-测试商行
                                    1和3是省心版
                                    2和4是专业版
                                    目前只能专业版转省心版，2->1；4->3
                                */}
                <FormItem label='请更换商行版本'>
                  {getFieldDecorator('type', {
                    initialValue: type === 2 || type === 4 ? 2 : 1,
                  })(
                    <Radio.Group>
                      <Radio value={2}>专业版</Radio>
                      <Radio value={1}>省心版</Radio>
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>

            <FormItem wrapperCol={{ span: 16 }}>
              <Button type='primary' htmlType='submit'>
                保存
              </Button>
              <Button onClick={handleCancelForConvertType} style={{ marginLeft: '18px' }}>
                关闭
              </Button>
            </FormItem>
          </Form>
        </Modal>

        <Modal
          title={`转换商行版本`}
          visible={visible}
          onCancel={this.handleCancel}
          footer={null}
          width='600px'
        >
          <Form {...layout} onSubmit={this.handleSubmit2} labelAlign='right'>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label='当前市场'>
                  <span className='ant-form-text'>{stallDetails.marketName || '- -'}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem label='市场名称'>
                  {getFieldDecorator('marketId', {
                    initialValue: stallDetails.marketId,
                    rules: [{ required: false, message: '请选择市场!' }],
                  })(
                    <Select
                      placeholder='请选择市场'
                      style={{ minWidth: '100%' }}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.isEdit ? true : false}
                    >
                      {marketData.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>

            <FormItem {...tailLayout}>
              <Button type='primary' htmlType='submit'>
                保存
              </Button>
              <Button onClick={this.handleCancel} style={{ marginLeft: '18px' }}>
                关闭
              </Button>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ConvertType;
