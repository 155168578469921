import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Modal, Form, Input, Spin, Space, Button, Radio, message, InputNumber } from 'antd-v5';

import { getBusinessUpdate } from '../../../../state/request/serve';
import { categoryEnum, typeEnum } from '../../enum';

import UploadFile from '../../../../component/upload';

import './index.less';

const { TextArea } = Input;

const AddNewEditor = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onFinish = async (values) => {
    const { extend = {} } = data;
    const param = {
      ...extend,
      detailUrl: values.detailUrl,
      category: values.category,
      price: values.price,
      discountPrice: values.discountPrice,
    };
    delete values.detailUrl;
    delete values.category;
    delete values.discountPrice;
    delete values.price;

    // 增值服务需要传extend
    const res = await getBusinessUpdate({ ...values, extend: param });
    if (res.code === 200) {
      message.info('编辑增值服务成功');
      setOpen(false);
      props.refresh && props.refresh();
    }
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      if (data) {
        // detailUrl: data.extend?.detailUrl
        form.setFieldsValue({ ...data, ...data.extend });
        setData(data);
      }
    },
  }));

  const ExtraContent = () => {
    const priceValue = Form.useWatch('price', form);

    const onChange = (event) => {
      if (!event.target.value) {
        form.setFieldsValue({ discountPrice: undefined });
      }
    };
    return (
      <Form
        className='add-new-editor-form'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        layout='horizontal'
        preserve={false}
        onFinish={onFinish}
        initialValues={{
          typeEnum: 1,
        }}
      >
        <Form.Item name='id' hidden>
          <div />
        </Form.Item>
        <Form.Item
          label='增值功能名称'
          name='name'
          rules={[{ required: true, message: '请输入增值服务名称' }]}
        >
          <Input maxLength={8} placeholder='请输入增值功能名称' />
        </Form.Item>
        <Form.Item
          label='功能说明'
          name='description'
          rules={[{ required: true, message: '请输入增值服务功能说明' }]}
        >
          <TextArea maxLength={40} showCount placeholder='请输入功能说明' />
        </Form.Item>
        <Form.Item label='图标' name='iconUrl' rules={[{ required: true, message: '请上传图标' }]}>
          <UploadFile url={data.iconUrl} onChange={() => {}} />
        </Form.Item>
        <Form.Item
          label='详情页图片'
          name='detailUrl'
          rules={[{ required: true, message: '请上传详情页图片' }]}
        >
          <UploadFile max={5} url={data.extend?.detailUrl} onChange={() => {}} />
        </Form.Item>
        <Form.Item label='付费方式' name='typeEnum'>
          <Radio.Group>
            <Space>
              {typeEnum.map((item) => (
                <Radio key={item.key} value={item.key}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='分类' name='category' rules={[{ required: true, message: '请选择分类' }]}>
          <Radio.Group value={data.extend?.price}>
            <Space>
              {categoryEnum.map((item) => (
                <Radio key={item.key} value={item.key}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label='原价'
          name='price'
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value.length > 20) {
                  return Promise.reject(new Error('最长支持20个字符!'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            value={data.extend?.price}
            placeholder='请输入原价'
            onChange={onChange}
            allowClear
            min={1}
            controls={false}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label='优惠价'
          name='discountPrice'
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value.length > 20) {
                  return Promise.reject(new Error('最长支持20个字符!'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            value={data.extend?.discountPrice}
            placeholder='请输入优惠价'
            allowClear
            disabled={!priceValue && priceValue !== 0}
            controls={false}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          {...{
            wrapperCol: { offset: 16, span: 8 },
          }}
        >
          <Space>
            <Button htmlType='button' onClick={() => setOpen(false)}>
              取消
            </Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      title={`${data.id ? '编辑' : '新增'}增值服务`}
      open={open}
      destroyOnClose
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Spin spinning={loading}>
        <ExtraContent />
      </Spin>
    </Modal>
  );
});

export default AddNewEditor;
