import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';

export const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const actionList = (actions) => [
  {
    key: 'add',
    type: 'primary',
    icon: 'plus',
    label: '添加柜台',
    action: actions['handleEdit'],
  },
];

export const colActions = [
  {
    key: 'handleEdit',
    type: 'link',
    label: '编辑',
  },
  {
    key: 'handleDelete',
    type: 'link',
    label: '删除',
  },
];

export const tableHeader = [
  {
    dataIndex: 'no',
    title: '序号',
  },
  {
    dataIndex: 'type',
    title: '柜台类型',
  },
  {
    dataIndex: 'payMethod',
    title: '支付类型',
  },
  {
    dataIndex: 'branchId',
    title: '分行代码',
  },
  {
    dataIndex: 'posId',
    title: '柜台号',
  },
  {
    dataIndex: 'merchantId',
    title: '商户号',
  },
  {
    dataIndex: 'pubKey',
    title: '商户公钥',
  },
  {
    dataIndex: 'ruleId',
    title: '费率',
  },
  {
    dataIndex: 'entName',
    title: '商户名称',
  },

  {
    dataIndex: 'sysId',
    title: '汇付系统号',
  },

  {
    dataIndex: 'productId',
    title: '汇付产品号',
  },

  {
    dataIndex: 'priKey',
    title: '汇付私钥',
  },

  {
    dataIndex: 'cashierName',
    title: '收银员',
  },
  {
    dataIndex: 'action',
    title: '操作',
    align: 'center',
  },
];

export const colsCfg = (actions, ruleList) => [
  {
    dataIndex: 'no',
    render: (_, __, index) => index + 1,
  },
  {
    dataIndex: 'type',
    render: (text) => (text == '1' ? '扫码枪' : '线上支付'),
  },
  {
    dataIndex: 'payMethod',
    render: (text) => {
      if (text === '1') {
        return '龙支付';
      } else if (text === '2') {
        return '惠市宝';
      } else if (text === '3') {
        return '微企付';
      } else if (text === '4') {
        return '汇付天下';
      }
      return '';
    },
  },
  {
    dataIndex: 'ruleId',
    render: (text) => {
      let ruleName = '';
      ruleList.map((item) => {
        if (item.code === text) {
          ruleName = item.attribute;
          return;
        }
      });
      return ruleName;
    },
  },
  {
    dataIndex: 'pubKey',
    ellipsis: true,
    render: (text) => (
      <Tooltip title={text}>
        <div className='table-elli'>{text}</div>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'sysId',
    ellipsis: true,
    render: (text) => (
      <Tooltip title={text}>
        <div className='table-elli'>{text}</div>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'productId',
    ellipsis: true,
    render: (text) => (
      <Tooltip title={text}>
        <div className='table-elli'>{text}</div>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'priKey',
    ellipsis: true,
    render: (text) => (
      <Tooltip title={text}>
        <div className='table-elli'>{text}</div>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'action',
    render: (_, record) => {
      const acList = Object.keys(actions).map((key) => ({
        ...colActions.find((item) => item.key === key),
        action: actions[key],
      }));
      return (
        <div className='actions'>
          {acList.map(({ key, action, type, label }) => {
            if (key === 'handleDelete') {
              return (
                <Popconfirm key={key} title='确认删除?' onConfirm={() => action(record)}>
                  <Button type={type} size='small' className='delete'>
                    {label}
                  </Button>
                </Popconfirm>
              );
            }
            return (
              <Button key={key} type={type} size='small' onClick={() => action(record)}>
                {label}
              </Button>
            );
          })}
        </div>
      );
    },
  },
];
